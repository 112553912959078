import * as React from 'react';
import styled from 'styled-components';

import { navigate } from 'gatsby';
import {
  Container,
  ContainerSmall,
  TextContainer,
  MainTableItemContainer,
  Card,
} from 'components/Containers';
import { Results, SearchDropdown } from 'components/SearchDropdown';
import { Seo } from 'components/Seo';

import importedJson from 'assets/support.json';

import { smallTabletMQ } from 'styles/breakpoints';

const Body = styled(ContainerSmall)`
  display: inline-block;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};

  a {
    color: ${({ theme }) => theme.colors.blue[0]};
    text-decoration: underline;
  }

  p {
    color: ${({ theme }) => theme.colors.black};
    padding: 5px 0;
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  min-height: 250px;
  border: 1px solid ${({ theme }) => theme.colors.grey[3]};
`;

const ColumnContainer = styled(Container)`
  flex-direction: column;
`;

const RowContainer = styled(Container)`
  padding: 0;
  max-width: 100%;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  ${smallTabletMQ(`
    flex-direction: column;
    margin-bottom: 10px;
  `)}
`;

const TitleColumn = styled.div`
  flex: 5;
`;

const Title = styled.h3`
  display: flex;
`;

const HeaderContainer = styled.div`
  flex-direction: column;
  margin: 0;
  max-width: 100%;
`;

const StyledSearchDropdown = styled(SearchDropdown)`
  width: 350px;
  ${smallTabletMQ(`
    width: 100%;
  `)}
  ${Results} {
    max-height: 300px;
  }
`;

const StyledTextContainer = styled(TextContainer)`
  min-width: 480px;
  width: 100%;
  align-items: start;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  padding: 0;
  h2 {
    color: ${({ theme }) => theme.colors.blue[0]};
  }

  ${smallTabletMQ(`
    min-width: unset;
    padding: 10px 0;
    flex-direction: column;
    width: 100%;
  `)}
`;

const SupportCardsContainer = styled.div`
  display: flex;
  min-height: 350px;
  flex-direction: column;
  align-items: center;
  ${smallTabletMQ(`
    flex-direction: column;
    align-items: center;
  `)}
`;

const CardsContainer = styled.div`
  padding: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[3]};
  ${({ theme }) => theme.shadows[0]}
  flex-wrap: wrap;
  border-radius: 6px;
`;


const BreadcrumbRow = styled.div`
  display: flex;
  ${smallTabletMQ(`
    align-self: self-start;
  `)}
`;

const Image = styled.img`
  width: 100%;
  border-radius: 2px;
  height: 400px;
  ${smallTabletMQ(`
    height: 100%;
    max-height: 300px;
  `)}
`;

const Breadcrumb = styled.p<{ isActive?: boolean }>`
  font-weight: ${({ isActive }) => isActive ? '900' : 'normal'};
  color: ${({ isActive, theme }) => isActive ? theme.colors.black : theme.colors.grey[0]};
  padding: 0 10px 20px 0;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  white-space: ${({ isActive }) => isActive ? `normal` : `nowrap`};
  line-height: 15px;
  user-select: none;
  ${smallTabletMQ(`
    padding: 0 5px 10px 0;
  `)}
`;

const ImageContainer = styled.div`
  flex-direction: row;
  align-self: center;
  padding-top: 20px;
  ${smallTabletMQ(`
    padding: 10px 0;
  `)}
`;

const SupportDrilldown: React.FC<ISupportDrilldownProps> = ({
  id, index, data, allQuestions,
}) => {
  const SupportJSON = importedJson;
  const json = (id && id in SupportJSON) ? SupportJSON[id] : undefined;
  let jsonData: ISupportJSONData | undefined;

  // If is a list of items
  if (json && index && Object.prototype.toString.call(json.data) === '[object Array]') {
    jsonData = json.data.find((item) => item.url === index);
  }

  if (!json) {
    navigate('/support')?.catch(() => undefined);

    return null;
  }

  if (!jsonData && index) {
    navigate(`/support/${id ?? ``}`)?.catch(() => undefined);

    return null;
  }

  return (
    <Container>
      <div>
        <HeaderContainer>
          <BreadcrumbRow>
            <Breadcrumb
              isActive={!id}
              onClick={async() => navigate(`/support`)}
            >
            Support Hub
            </Breadcrumb>
            {id && json.title && (
              <>
                <Breadcrumb>{`/`}</Breadcrumb>
                <Breadcrumb
                  isActive={!index}
                  onClick={async() => navigate(`/support/${id}`)}
                >
                  {json.title}
                </Breadcrumb>
              </>
            )}
            {index && jsonData && (
              <>
                <Breadcrumb>{`/`}</Breadcrumb>
                <Breadcrumb
                  isActive
                  onClick={async() => navigate(`/support/${id}/${jsonData?.url}`)}
                >
                  {jsonData.title}
                </Breadcrumb>
              </>
            )}
          </BreadcrumbRow>
          <RowContainer>
            <StyledTextContainer>
              <h2>{jsonData?.title ?? json.title}</h2>
            </StyledTextContainer>
            <StyledSearchDropdown
              placeholder="Search for your question"
              searchObjects={allQuestions.map((question) => ({
                key: question.url,
                value: question.title,
                question,
                synonymSentences: question.synonymSentences,
              }))}
              onSelect={(obj) => {
                if (obj?.question) {
                  navigate(
                    `/support/${obj?.question?.key}/${obj?.question?.url}`
                  )?.catch(() => undefined);
                }
              }}
            />
          </RowContainer>
        </HeaderContainer>
        {!jsonData ? (
          <SupportCardsContainer>
            <Seo
              title={`WireBuddy - Support - ${json.title}`}
              description={json.title}
              lang="en"
              image="src/assets/favicons/WireBuddyIconNew.png"
            />
            <CardsContainer>
              {json?.data.map((item: IGenericObject, newIndex: number) => (
                <MainTableItemContainer
                  key={`list-support-${newIndex}`}
                  onClick={async() => navigate(`/support/${id}/${item.url}`)}
                >
                  <TitleColumn>
                    <Title>{item.title}</Title>
                  </TitleColumn>
                </MainTableItemContainer >
              ))}
            </CardsContainer>
          </SupportCardsContainer>
        ) : (
          <SupportCardsContainer>
            <Seo
              title={`WireBuddy - Support - ${jsonData.title}`}
              description={jsonData.title}
              lang="en"
              image="src/assets/favicons/WireBuddyIconNew.png"
            />
            <StyledCard>
              <ColumnContainer>
                <Body dangerouslySetInnerHTML={{ __html: jsonData?.body ?? '' }}/>
                <ImageContainer>
                  {!!data?.images?.edges && data.images.edges.map((item) => {
                    if (item?.node && jsonData?.src.includes(item.node.relativePath)) {
                      return <Image
                        key={`support-image-${item.node.relativePath}`}
                        src={item.node.childImageSharp.fixed.src}
                        alt=""
                        width={item.node.childImageSharp.fixed.width}
                        height={item.node.childImageSharp.fixed.height}
                      />;
                    }

                    return null;
                  })}
                </ImageContainer>
              </ColumnContainer>
            </ StyledCard>
          </SupportCardsContainer>
        )}
      </div>
    </Container>
  );
};

export default SupportDrilldown;
