import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Router } from '@reach/router';

import { navigate, graphql } from 'gatsby';

import {
  Container,
  TextContainer,
  Blue,
  Beige,
} from 'components/Containers';
import { Seo } from 'components/Seo';
import {
  WhiteHeader,
  CenteredWhiteHeader,
  WhiteDetails,
} from 'components/Text';
import { ContactForm } from 'forms/Contact';
import { Icon } from 'components/Icon';
import { Results, SearchDropdown } from 'components/SearchDropdown';
import { NavBar } from 'components/NavBar';
import { Footer } from 'components/Footer';
import SupportDrilldown from 'components/SupportDrilldown';

import json from 'assets/support.json';

import {
  customer,
  cog,
  card,
  calendar,
  questionCircle,
  hammer,
  paperOutline,
  locationPin,
  receipt,
} from 'assets/icons';


import {
  extraLargeDesktopMQ,
  largeDesktopMQ,
  largeMobileMQ,
  largeTabletMQ,
  mediumDesktopMQ,
  smallDesktopMQ,
  smallTabletMQ,
} from 'styles/breakpoints';
import { StaticImage } from 'gatsby-plugin-image';

const RadialGradient = styled(Blue)`
  /* radial gradient light grey very low opacity */
  /* light grey at 10% opacity */
  background-image: radial-gradient(
    circle at 67% 50%,
    rgba(243, 244, 246, 0.05) 15%,
    transparent
  );
  ${smallTabletMQ(`
    background-image: unset;
  `)}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const StyledTextContainer = styled(TextContainer)`
  min-width: 480px;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  padding: 0;

  ${smallTabletMQ(`
    min-width: unset;
    flex-direction: column;
    width: 100%;
  `)}
`;

const StyledSearchDropdown = styled(SearchDropdown)`
  max-width: 500px;
  ${Results} {
    max-height: 300px;
  }
`;

const SupportCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${smallTabletMQ(`
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
  `)}
`;

const CardsContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ColumnContainer = styled(Container)`
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const CardTitleText = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.colors.blue[0]};
  font-weight: 600;
  ${smallTabletMQ(`
    font-size: 18px;
  `)}
`;

const SupportCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  cursor: pointer;
  margin: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[3]};
  padding: 15px;
  width: 230px;
  ${({ theme }) => theme.shadows[0]}
  :hover {
    background-color: ${({ theme }) => theme.colors.grey[2]};
  }
  ${smallTabletMQ(`
    width: calc(50% - 20px);
    margin: 10px;
    padding: 10px;
  `)}
`;

const SupportSide = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-left: 85px;
  justify-content: center;
  z-index: 2;
  > * {
    z-index: 2;
    position: relative;
  }
  box-sizing: border-box;

  ${smallDesktopMQ(`
    padding-left: 50px;
  `)}

  ${smallTabletMQ(`
    width: 100%;
    height: unset;
    margin: 0;
    padding: 15px;
    align-items: center;
    justify-content: center;
  `)}
  &::after {
    ${({ theme }) => smallTabletMQ(`
      background-color: ${theme.colors.blue[0]};
      opacity: 0.8;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    `)}
  }
`;

const MobileSupportSide = styled(SupportSide)`
  ${smallTabletMQ(`display: none;`)}
`;
const IconContainer = styled.div`
  text-align: center;
  padding: 30px 0;
`;

const DemoSide = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-left: 15px;
  justify-content: space-evenly;
  z-index: 2;
  > * {
    z-index: 2;
    position: relative;
  }
  box-sizing: border-box;

  ${smallTabletMQ(`
    width: 100%;
    height: unset;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  `)}
`;


const GreyDemoSide = styled(DemoSide)`
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.rgba[0]};
  width: 50%;
  ${largeTabletMQ(`
    width: 65%;
  `)}
  ${({ theme }) => smallTabletMQ(`
    position: unset;
    background-color: ${theme.colors.rgba[2]};
    width: 100%;
  `)}
`;

const TranslucentBlue = styled(Blue)`
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 50%;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  ${smallTabletMQ(`
    display: none;
  `)}
`;

const DemoImageContainer = styled.div`
  display: flex;
  height: 700px;
  position: relative;
  justify-content: flex-end;
  img {
    border-radius: unset;
    position: absolute;
  }
  ${smallTabletMQ(`
    flex-direction: column;
    height: unset;
  `)}
  ${({ theme }) => smallTabletMQ(`background-color: ${theme.colors.blue[0]}`)}
  .backgroundImage {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const WidthWhiteDetails = styled(WhiteDetails)`
  max-width: 380px;
`;

const SupportHeader = styled(WhiteHeader)`
  text-align: start;
  align-items: flex-start;
  display: flex;
  line-height: 1.5;
  justify-content: flex-start;
  margin-bottom: 10px;
  ${extraLargeDesktopMQ(`
    font-size: 35px;
  `)}
  ${mediumDesktopMQ(`
    font-size: 28px;
  `)}
  ${smallTabletMQ(`
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    font-size: 25px;
  `)}
  ${largeMobileMQ(`
    font-size: 20px;
  `)}
`;

const DemoContainer = styled(Container)`
  width: 100%;
  justify-content: flex-end;
  ${smallTabletMQ(`
    padding: 0;
  `)}
  margin-left: 0;
`;

const StyledIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.yellow[0]};
  ${smallTabletMQ(`
    svg {
      width: 50px;
      height: 50px;
    }
  `)}
`;

const FullHeightRouter = styled(Router)`
  height: 100%;
`;

const SupportImageContainer = styled.div`
  display: flex;
  height: 450px;

  position: relative;
  justify-content: flex-end;
  img {
    border-radius: unset;
    position: absolute;
  }
  ${smallTabletMQ(`
    flex-direction: column;
    margin-top: -50px;
    height: 520px;
  `)}
  ${largeMobileMQ(`
    height: 350px;
    margin-top: -30px;
  `)};
  ${({ theme }) => smallTabletMQ(`background-color: ${theme.colors.blue[0]}`)}
  .backgroundImage {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const HomeDetails = styled.h3`
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
  ${largeDesktopMQ(`
    font-size: 18px;
  `)}
  ${smallTabletMQ(`
    margin: 10px 0;
    font-size: 16px;
  `)}
  color: ${({ theme }) => theme.colors.blue[0]};
`;

const ColContainer = styled(Container)`
  flex-direction: column;
  ${smallTabletMQ(`
    padding-top: 30px;
    padding-bottom: 30px;
  `)}
`;

const ColDemoContainer = styled(ColContainer)`
  margin-left: 0;
`;
const SupportContainer = styled(Container)`
  width: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
  ${smallTabletMQ(`padding: 0`)}
`;

export const pageQuery = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 1205, height: 1005) {
              fallback: base64
              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`;

const MainSupport: React.FC<{
  path: string;
  allQuestions: ISupportQuestion[];
}> = ({ allQuestions }) => {
  const Icons = {
    customer,
    cog,
    card,
    calendar,
    questionCircle,
    briefcase: hammer,
    paperOutline,
    locationPin,
    receipt,
  };

  const theme: ITheme = React.useContext(ThemeContext) as ITheme;
  const SupportJSON = json;

  return (
    <>
      <SupportImageContainer>
        <StaticImage
          alt="support man laptop"
          src="../images/support-laptop.jpg"
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
          }}
          className="backgroundImage"
          loading="eager"
          placeholder="blurred"
          imgStyle={{
            objectFit: 'cover',
            objectPosition: '50% 20%',
          }}
          quality={100}
        />
        <SupportContainer>
          <MobileSupportSide />
          <SupportSide>
            <SupportHeader>
              Need help with your <br/> WireBuddy account?
            </SupportHeader>
            <WidthWhiteDetails>
              We’re here to help. Check out our support guides or if you can’t
              find what you need reach out to us.
            </WidthWhiteDetails>
          </SupportSide>
        </SupportContainer>
        <TranslucentBlue/>
    </SupportImageContainer>
    <Beige>
      <ColumnContainer>
        <HeaderContainer>
          <StyledTextContainer>
            <h1>How can we help?</h1>
          </StyledTextContainer>
          <HomeDetails>
            Enter key words or the type question you need help with?
          </HomeDetails>
          <StyledSearchDropdown
            placeholder="Eg. “Company logo” OR “How do I add my company logo?”"
            searchObjects={allQuestions.map((question) => ({
              key: question.url,
              value: question.title,
              question,
              synonymSentences: question.synonymSentences,
            }))}
            onSelect={(obj) => {
              if (obj?.question) {
                navigate(`/support/${obj?.question?.key}/${obj?.question?.url}`)
                  .catch(() => undefined);
              }
            }}
          />
        </HeaderContainer>
      </ColumnContainer>
    </Beige>
    <RadialGradient>
      <ColumnContainer>
        <CenteredWhiteHeader>
          Explore our support hub
        </CenteredWhiteHeader>
        <SupportCardsContainer>
          <CardsContainer>
            {Object.keys(SupportJSON).map((key, index) => (
              <SupportCard
                key={`${key}-${index}`}
                onClick={async() => navigate(key) }>
                <IconContainer>
                  <StyledIcon
                    // @ts-expect-error
                    path={Icons[SupportJSON[key].icon]}
                    height={50}
                    width={50}
                    fill={theme.colors.blue[1]}
                  />
                </IconContainer>
                <CardTitleText>
                  {SupportJSON[key].title}
                </CardTitleText>
              </SupportCard>
            ))}
          </CardsContainer>
        </SupportCardsContainer>
      </ColumnContainer>
    </RadialGradient>
    <DemoImageContainer>
      <StaticImage
        alt="support-text"
        src="../images/support-text.jpeg"
        className="backgroundImage"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        }}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: '50% 30%',
        }}
        placeholder='blurred'
        quality={100}
      />
      <DemoContainer>
        <DemoSide />
        <GreyDemoSide>
          <ColDemoContainer>
            <h1>Need to talk to a human? <br />Contact us</h1>
            <HomeDetails>
              Our support team is ready to help and will get back to you shortly.
            </HomeDetails>
          <ContactForm />
          </ColDemoContainer>
        </GreyDemoSide>
      </DemoContainer>
    </DemoImageContainer>
    </>
  );
};


const Support: React.FC<{ data: IAllImageQuery }> = ({ data }) => {
  let allQuestions: ISupportQuestion[] = [];
  const SupportJSON = json;
  Object.keys(SupportJSON).map((key) => {
    allQuestions = allQuestions.concat(key in SupportJSON
      ? SupportJSON[key].data.map((question) => ({
        key,
        ...question,
      }))
      : []);
  });

  return (
    <>
      <NavBar />
      <Seo
        title="WireBuddy - Support"
        description="All your jobs, simply in one place"
        lang="en"
        image="src/assets/favicons/WireBuddyIconNew.png"
      />
      <FullHeightRouter>
        <MainSupport allQuestions={allQuestions} path="/support" />
        <SupportDrilldown
          allQuestions={allQuestions}
          data={data}
          path="/support/:id"
        />
        <SupportDrilldown
          allQuestions={allQuestions}
          data={data}
          path="/support/:id/:index"
        />
      </FullHeightRouter>
      <Footer isBordered />
    </>
  );
};


export default Support;
