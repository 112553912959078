export const searchSupportResults = (arr: ISearchObject[], searchValue: string) => {
  const searchTerms = searchValue.split(' ');

  return arr.map((item) => {
    const { question } = item;
    const { importantWords } = question;
    const rank = searchTerms.reduce((acc, termUnAdjusted) => {
      const term = termUnAdjusted.toLowerCase();
      const importantWordKeys = Object.keys(importantWords).map((key) => key.toLowerCase());
      const importantWordWeights = Object.values(importantWords);
      const importantWordKeysWithTerm = importantWordKeys.filter((key) =>
        term.length > 2 && key.includes(term));
      const importantWordWeightsWithTerm = importantWordWeights.filter((value) => value > 0);
      const importantWordWeightsWithTermSum = importantWordWeightsWithTerm.reduce((acc2, value) =>
        acc2 + value, 0);
      const questionWithTerm = question.title.toLowerCase().includes(term) && term.length > 2;
      const bodyWithTerm = question.body.toLowerCase().includes(term) && term.length > 2;
      if (importantWordKeysWithTerm.length > 0) {
        return acc + importantWordWeightsWithTermSum;
      }
      if (questionWithTerm) {
        return acc + 1;
      }
      if (bodyWithTerm) {
        return acc + 0.5;
      }

      return acc;
    }, 0);

    return {
      ...item,
      rank,
    };
  }).filter((item) => item.rank > 0).sort((a, b) => b.rank - a.rank);
};
