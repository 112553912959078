import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Icon } from 'components/Icon';

import { largeMobileMQ, smallTabletMQ } from 'styles/breakpoints';

import { searchSupportResults } from 'utils/filter';

import { search, x } from 'assets/icons';

import { config } from 'constants/config';


const MAX_RESULTS = 6;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchDropdownContainer = styled.div<{ isInFocus: boolean; isDisabled: boolean }>`
  align-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.grey[5]};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 45px;
  transition: opacity .5s ease-in-out;
  ${({ isInFocus }) => isInFocus && `
    opacity: 1;
  `};
  ${({ isDisabled, theme }) => !isDisabled ? `
    :hover {
      border: 1px solid ${theme.colors.yellow[0]};
      opacity: 1;
    };
  ` : `
    span {
      cursor: not-allowed;
      text-decoration: none;
    }
    cursor: not-allowed;
    opacity: 0.6;
    user-select: none;
  `};
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.grey[0]};
    color: ${({ theme }) => theme.colors.black};
  };

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Input = styled.input<{ isHidden: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: none;
  font-size: 1.2em;
  padding-left: 10px;
  text-overflow: ellipsis;
  width: 100%;
  ${({ isHidden }) => isHidden && `
    width: 0;
    visibility: hidden;
    opacity: 0;
  `};
  :focus, :active {
    outline: none!important;
    border: none!important;
  }
  ${smallTabletMQ(`
    padding-left: 16px;
  `)};
  ${largeMobileMQ(`
    padding-left: 12px;
  `)};
`;

const SearchIcon = styled(Icon)`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-right: 24px;
  ${smallTabletMQ('padding-right: 12px')};
  ${largeMobileMQ(`
    padding-right: 8px;
  `)};
`;

const XIcon = SearchIcon;

const SearchResult = styled.div`
  align-self: center;
  margin-left: 12px;
  width: 100%;
`;

export const Results = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.grey[5]} !important;
  max-height: 100px;
  overflow: auto;
  position: absolute;
  width: calc(100% - 2px);
  z-index: 10;
`;

const Result = styled.div<{ isFocus: boolean }>`
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.yellow[2]};
    opacity: 0.8;
  }
  padding: 10px;
  ${({ isFocus, theme }) => (isFocus && `
    background-color: ${theme.colors.yellow[2]};
    opacity: 0.8;
  `)};
`;

const Divider = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.black};
  height: 0;
  margin: 0px 0;
  opacity: 0.08;
`;

export const SearchDropdown: React.FC<ISearchDropdownProps> = (props) => {
  const theme: ITheme = React.useContext(ThemeContext) as ITheme;

  // @ts-expect-error
  const inputRef = React.useRef<HTMLInputElement>(undefined);

  const [isInFocus, setIsInFocus] = React.useState(false);
  const [position, setPosition] = React.useState(-1);
  const [searchValue, setSearchValue] = React.useState('');
  const [result, setResult] = React.useState<ISearchObject | undefined>(props.initialObject);
  const [results, setResults] = React.useState<ISearchObject[] | undefined>(undefined);

  React.useEffect(() => {
    const runSearch = () => {
      let r: ISearchObject[] = [];

      if (props.searchObjects) {
        r = searchSupportResults(props.searchObjects, searchValue) ;
      } else {
        r = [];
      }

      setResults(r);
    };

    runSearch();
  }, [searchValue]);

  React.useEffect(() => {
    if (!!props.initialObject) {
      setResult(props.initialObject);
    }
  }, [props.initialObject?.value]);

  const handleKeyDown = React.useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowLeft': {
        handleSetPosition(position - 1);
        break;
      }
      case 'ArrowDown':
      case 'ArrowRight': {
        handleSetPosition(position + 1);
        break;
      }
      case 'Enter': {
        e.preventDefault();

        // Don't break. Carry to next one
      }
      case 'Tab': {
        // If we already have a result, we may want to move on to next input
        if (result) {
          break;
        }

        if (positionInRange(position)) {
          e.preventDefault();

          const _result = (results ?? [])[position];

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          handleSetResult(_result);
        }
        break;
      }
      case 'Escape': {
        e.preventDefault();

        handleClearSearch();
        break;
      }
      default: {
        // Results should be fixed. If we have a user with id 1 and name "James Vopni",
        // we don't want someone selecting that person, then thinking they've changed
        // the name to "James Vopnis" by adding an "s" for example. They should be
        // starting a new search in that case
        if (result) {
          handleClearSearch();
        }
      }
    }
  }, [position, results, result]);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [handleKeyDown]);

  const handleSetResult = (r?: ISearchObject) => {
    if (r) {
      setSearchValue('');

      if (inputRef.current) {
        inputRef.current.value = '';
      }

      if (props.onSelect) {
        props.onSelect(r);
      }
    }

    setResult(r);
  };

  const handleSetPosition = (pos: number) => {
    if ((positionInRange(pos) || pos === -1) && pos !== position) {
      setPosition(pos);
    }
  };

  const positionInRange = (pos: number): boolean => (
    pos < MAX_RESULTS && pos < (results ?? []).length && pos > -1
  );

  const handleClearSearch = () => {
    setSearchValue('');

    if (inputRef.current) {
      inputRef.current.value = '';
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSetPosition(-1);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSetResult(undefined);

    if (props.onSelect) {
      props.onSelect(undefined);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Container className={props.className}>
      <SearchDropdownContainer
        isInFocus={isInFocus || !!result}
        isDisabled={props.isDisabled ?? false}
      >
        {!!result && (
          <SearchResult>{result.value}</SearchResult>
        )}

        <Input
          autoComplete="off"
          isHidden={!!result}
          name="Search"
          onBlur={() => {
            setIsInFocus(false);
          }}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          onFocus={() => {
            setIsInFocus(true);
          }}
          placeholder={props.placeholder}
          ref={inputRef}
          value={result?.value}
          type="text"
          disabled={props.isDisabled ?? false}
        />

        {((!!result || !!searchValue) && !props.isDisabled) && (
          <XIcon
            fill={theme.colors.black}
            path={x}
            height={config.iconSize}
            width={config.iconSize}
            onClick={() => {
              handleClearSearch();
            }}
          />
        )}

        {!result && !searchValue && (
          <SearchIcon
            fill={theme.colors.black}
            path={search}
            height={config.iconSize}
            width={config.iconSize}
          />
        )}
      </SearchDropdownContainer>

      {!!searchValue && !!(results ?? []).length && !result && (
        <Results>
          {(results ?? []).slice(0, MAX_RESULTS).map((r, index) => (
            <React.Fragment
              key={`search-${r.key}`}
            >
              <Result
                isFocus={index === position}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  handleSetResult(r);
                }}
              >
                {r.value}
              </Result>

              {index !== (results ?? []).length - 1 && index !== MAX_RESULTS - 1 && (
                <Divider />
              )}
            </React.Fragment>
          ))}
        </Results>
      )}
    </Container>
  );
};
