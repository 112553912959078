import wireBuddyApi from './index';

export default {
  async post(data: IContactForm): Promise<void> {
    try {
      await wireBuddyApi.post('marketingSite/sendContactForm', data);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
