import * as React from 'react';
import styled from 'styled-components';


import { Button, TryItButton } from 'components/Button';
import { FormInput, FormTextArea } from 'components/FormInput';
import { Modal } from 'components/Modal';

import { validateEmail } from 'utils/validate';

import { smallTabletMQ } from 'styles/breakpoints';

import sendContactForm from 'api/sendContactForm';

import LoadingGearSvgWhite from 'assets/svgs/LoadingGearWhite.svg';

const InputContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  ${smallTabletMQ('width: 100%;')}
`;

const StyledInput = styled(FormInput)`
  width: 450px;
  height: 60px;
  ${smallTabletMQ('width: 100%;')}
`;

const StyledFormTextArea = styled(FormTextArea)`
  width: 450px;

  ${smallTabletMQ('width: 100%;')}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${smallTabletMQ('width: 100%;')}
`;

const StyledLoadingGear = styled(LoadingGearSvgWhite)`
  width: 100px;
  height: 100px;
  position: absolute;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const BlurredContainer = styled.div<{ blurred: boolean }>
`
  display: flex;
  align-items: center;
  justify-content: center;
  ${smallTabletMQ('width: 100%;')}

  ${({ blurred }) => blurred && `
    opacity: 0.8;
    filter: blur(4px);
  `}
`;

const MarginSubmitButton = styled(TryItButton)`
  margin-top: 20px;
`;

export const ContactForm: React.FC = () => {
  const INITIAL_STATE: IContactFormState = {
    formErrors: {},
    formTouched: {},
    isValid: false,
    contact: {
      email: '',
      message: '',
      name: '',
    },
  };

  const [formState, setFormState] = React.useState<IContactFormState>(INITIAL_STATE);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = React.useState(false);

  React.useEffect(() => {
    handleValidate();
  }, [formState.contact]);

  const handleSetFormErrors = (
    formErrors: IContactFormError, isValid: boolean, touch?: boolean,
  ) => {
    setFormState({
      ...formState,
      formErrors,
      formTouched: {
        email: touch ? true : formState.formTouched.email,
        name: touch ? true : formState.formTouched.name,
        message: touch ? true : formState.formTouched.message,
      },
      isValid,
    });
  };

  const handleChangeValue = (key: keyof IContactForm, value?: string) => {
    setFormState({
      ...formState,
      formTouched: {
        ...formState.formTouched,
        [key]: true,
      },
      contact: {
        ...formState.contact,
        [key]: value,
      },
    });
  };

  const handleSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.grecaptcha.ready(() => {
      // eslint-disable-next-line
      window.grecaptcha.execute(process.env.RECAPTCHA_KEY, { action: 'contact' })
        .then((token?: string) => {
          submitHelper(token);
        }, () => {
          submitHelper(undefined);
        });
    });
  };

  const loadRecaptchaOnFocus = () => {
    // check first if recaptcha is already loaded
    if (window.grecaptcha) {
      return;
    }

    const script = document.createElement('script');
    script.src =
      `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_KEY}`;
    document.body.appendChild(script);
  };

  const handleValidate = (touch?: boolean) => {
    const emailCheck = validateEmail(formState.contact.email);

    const formErrors = {
      email: !emailCheck.valid ? emailCheck.reason : undefined,
      message: !formState.contact?.message ? 'Please enter a message' : undefined,
      name: !formState.contact?.name ? 'Please enter a name' : undefined,
    };

    const isValid = Object.values(formErrors).reduce((acc, error) => (acc && !error), true);

    handleSetFormErrors(formErrors, isValid, touch);

    return isValid;
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  });

  const submitHelper = (token?: string) => {
    setIsFailModalOpen(false);
    setIsSuccessModalOpen(false);
    setIsLoading(true);
    sendContactForm.post({
      ...formState.contact,
      recaptchaToken: token,
    }).then(() => {
      setIsLoading(false);
      setIsSuccessModalOpen(true);
      setFormState(INITIAL_STATE);
    })?.catch(() => {
      setIsFailModalOpen(true);
      setIsLoading(false);
    });
  };

  return (
    <StyledContainer>
      <Modal
        isOpen={isFailModalOpen}
        handleCloseModal={() => {
          setIsFailModalOpen(false);
        }}
        title="Sending Contact message failed"
        content={<>
          <p>Sending a contact message has failed, please refresh and try again.</p>
          <Button onClick={() => {
            window.location.reload();
          }}>Refresh and Try again</Button>
        </>}
      />
      <Modal
        isOpen={isSuccessModalOpen}
        handleCloseModal={() => {
          setIsSuccessModalOpen(false);
        }}
        title="Your message has been sent to WireBuddy!"
        content={<>
          <p>Your message has been delivered to WireBuddy successfully!
            Please look out in your inbox for our email response! Have a great day!
          </p>
        </>}
      />
      <BlurredContainer blurred={isLoading}>
        <Form
          autoComplete="off"
          encType="application/json"
          onSubmit={(e) => {
            e.preventDefault();
            if (!(!formState.isValid || isLoading)) {
              handleSubmit();
            }
          }}
        >
          <InputContainer>
            <StyledInput
              name="fullName"
              label="Full Name"
              placeholder="John Smith"
              isRequired
              onFocus={loadRecaptchaOnFocus}
              isDisabled={isLoading}
              error={formState.formErrors.name}
              touched={formState.formTouched.name}
              value={formState.contact.name}
              handleChange={(value) => { handleChangeValue('name', value); }}
            />
          </InputContainer>

          <InputContainer>
            <StyledInput
              name="email"
              label="Email"
              placeholder="john.smith@wirebuddy.ca"
              isRequired
              isDisabled={isLoading}
              onFocus={loadRecaptchaOnFocus}
              error={formState.formErrors.email}
              touched={formState.formTouched.email}
              value={formState.contact.email}
              handleChange={(value) => { handleChangeValue('email', value); }}
            />
          </InputContainer>
          <InputContainer>
            <StyledFormTextArea
              name="message"
              label="Enter a question or message"
              placeholder="Enter a question or message"
              isRequired
              isDisabled={isLoading}
              onFocus={loadRecaptchaOnFocus}
              error={formState.formErrors.message}
              touched={formState.formTouched.message}
              value={formState.contact.message}
              handleChange={(value) => { handleChangeValue('message', value); }}
            />
          </InputContainer>
          <MarginSubmitButton
            isDisabled={!formState.isValid || isLoading}
          >
            Send Message
          </MarginSubmitButton>
        </Form>
      </BlurredContainer>
      {isLoading && <StyledLoadingGear/>}
      <div
        className="g-recaptcha"
        data-sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        data-size="invisible"
      ></div>
    </StyledContainer>
  );
};
